import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DUP_APP_TITLE } from 'src/App';
import { Body } from 'src/components/DUP/atoms/Body';
import { Header } from 'src/components/DUP/atoms/Header';
import { Footer } from 'src/components/DUP/molecules/Footer';
import { FormApplicant } from 'src/components/DUP/molecules/FormApplicant';
import { UploadFileSection } from 'src/components/DUP/molecules/UploadFileSection';
import { UploadInstructions } from 'src/components/DUP/molecules/UploadInstructions';
import { Line } from 'src/components/atoms/Line';
import { Loader } from 'src/components/atoms/Loader';
import Section from 'src/components/atoms/Section/Section';
import { ResultMessage } from 'src/components/molecules/ResultMessage';
import { ResultMessageType } from 'src/components/molecules/ResultMessage/ResultMessage';
import { useFormApplicant } from 'src/hooks/useFormApplicant';
import useInformation from 'src/hooks/useInformation';
import { useRequest, useResource } from 'src/hooks/useResource';
import { useSnackbarProvider } from 'src/hooks/useSnackbarProvider';
import {
  ApplicationSubmitResponse,
  DupApplicationType,
  Proof,
  SessionApplication,
  SessionProperty
} from 'src/types/api';

export const DUPPage: React.FC<{
  type: DupApplicationType;
  application: SessionApplication;
  onUpdateApplication: (
    updates: Partial<SessionApplication>
  ) => Promise<SessionApplication | { error: string }>;
}> = ({ type, application, onUpdateApplication }) => {
  useEffect(() => {
    if (window.heap) {
      window.heap.trackPageview({ title: DUP_APP_TITLE });
    }
  });

  const { showSnackbar, SnackTypes, VariantType } = useSnackbarProvider();
  const { t } = useTranslation();
  const { makeRequest } = useRequest();

  const [property] = useResource<SessionProperty | { error: string }>(`/session/property`);
  const [proofs = [], { refresh }] = useResource<Proof[]>(`/session/documents`);
  const information = useInformation(type, property && 'name' in property ? property.name : '');

  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [proofIsProcessing, setProofIsProcessing] = React.useState(false);

  const formData = useFormApplicant({
    property: property && 'error' in property ? undefined : property,
    type,
    information,
    application,
    onUpdateApplication
  });

  const onSubmit = async () => {
    const savedApplication = await formData.onSave();

    if (savedApplication) {
      const response = await makeRequest<ApplicationSubmitResponse>({
        path: `/session/submit`,
        method: 'POST'
      });
      if ('error' in response) {
        showSnackbar(VariantType.error, t('dup.submission.error.title'), SnackTypes.none);
      } else if ('applicantId' in response) {
        setIsSubmitted(true);
      }
    }
  };

  if ('error' in application || (!!property && 'error' in property)) {
    return (
      <Section align="center">
        <ResultMessage
          type={ResultMessageType.error}
          title={t('dup.submission.error.title')}
          message={t('dup.submission.error.message')}
        />
      </Section>
    );
  }

  if (isSubmitted) {
    return (
      <Section align="center">
        <ResultMessage
          type={ResultMessageType.success}
          title={t('dup.submission.success.title')}
          message={t('dup.submission.success.message')}
        />
      </Section>
    );
  }

  if (!property) {
    return <Loader isFixed />;
  }

  return (
    <div>
      <Header information={information} type={type} />
      <Body>
        <FormApplicant
          type={type}
          information={information}
          property={property}
          application={application}
          onUpdateApplication={onUpdateApplication}
          formData={formData}
        />
        <Line />
        <UploadInstructions
          type={type}
          instructions={information.uploadInstruction}
          property={property}
        />
        <UploadFileSection
          type={type}
          proofs={proofs}
          refresh={() => refresh()}
          setProofIsProcessing={setProofIsProcessing}
        />
        <Footer
          information={information}
          isSubmittable={!formData.saveDisabled && !!proofs.length}
          proofIsProcessing={proofIsProcessing}
          isProofsHasErrors={proofs.some((p) => !!p.jobs_error?.length)}
          onSubmit={onSubmit}
        />
      </Body>
    </div>
  );
};
