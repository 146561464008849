import React, { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { SessionProvider } from 'src/context/SessionContext';
import { configVars } from 'src/helpers';
import { DUPPageProvider } from 'src/pages/DUPPage/DUPPageProvider';
import { PartnerTermsPage } from 'src/pages/PartnerTermsPage';
import { PrivacyPolicyPage } from 'src/pages/PrivacyPolicyPage';
import { TermsPage } from 'src/pages/TermsPage';
import { WelcomePage } from 'src/pages/WelcomePage';
import { DupApplicationType } from 'src/types/api';

export const DUP_APP_TITLE = 'Document Portal';

export const App: React.FC = () => {
  useEffect(() => {
    const favicon = document.getElementById('favicon') as HTMLAnchorElement;
    favicon.href = '/assets/images/DocumentIcon.svg';
    document.title = DUP_APP_TITLE;

    if (window.heap) {
      window.heap.clearEventProperties();
    }
  });

  return (
    <>
      {configVars.heap.app_id && (
        <HelmetProvider>
          <Helmet>
            <script type="text/javascript">
              {`window.heapReadyCb=window.heapReadyCb||[],window.heap=window.heap||[],heap.load=function(e,t){window.heap.envId=e,window.heap.clientConfig=t=t||{},window.heap.clientConfig.shouldFetchServerConfig=!1;var a=document.createElement("script");a.type="text/javascript",a.async=!0,a.src="https://cdn.us.heap-api.com/config/"+e+"/heap_config.js";var r=document.getElementsByTagName("script")[0];r.parentNode.insertBefore(a,r);var n=["init","startTracking","stopTracking","track","resetIdentity","identify","identifyHashed","getSessionId","getUserId","getIdentity","addUserProperties","addEventProperties","removeEventProperty","clearEventProperties","addAccountProperties","addAdapter","addTransformer","addTransformerFn","onReady","addPageviewProperties","removePageviewProperty","clearPageviewProperties","trackPageview"],i=function(e){return function(){var t=Array.prototype.slice.call(arguments,0);window.heapReadyCb.push({name:e,fn:function(){heap[e]&&heap[e].apply(heap,t)}})}};for(var p=0;p<n.length;p++)heap[n[p]]=i(n[p])};
  heap.load("${configVars.heap.app_id}");`}
            </script>
          </Helmet>
        </HelmetProvider>
      )}
      <BrowserRouter>
        <Routes>
          <Route
            path="/application/applyonbehalf/:companyId/:propertyId"
            element={
              <SessionProvider applicationType={DupApplicationType.LEASING_TEAM}>
                <DUPPageProvider />{' '}
              </SessionProvider>
            }
          />
          <Route
            path="/application/apply/:companyId/:propertyId"
            element={
              <SessionProvider applicationType={DupApplicationType.UNAUTHENTICATED_USER}>
                <DUPPageProvider />{' '}
              </SessionProvider>
            }
          />
          <Route path="/terms" element={<TermsPage />} />
          <Route path="/partner-terms" element={<PartnerTermsPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="/" element={<WelcomePage />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};
