/**
 * This header component is intended to be usd with the DUPWizard component.
 */
import { useEffect } from 'react';
import { IconColor } from 'src/components/atoms/Icon';
import { TextColor } from 'src/components/atoms/Text';
import HideOnMobile from 'src/components/DUP/atoms/HideInMobile';
import { LanguageSelector } from 'src/components/organisms/LanguageSelector';
import { useWizardSteps, WizardStepInfo } from 'src/hooks/useWizardSteps';
import { SessionProperty } from 'src/types/api';
import {
  Container,
  H1,
  HeaderRow,
  ProgressContainer,
  PropertyName,
  Step,
  Subtitle
} from './styles';

interface ProgressProps {
  activeStep: number;
  goToStep: (step: number) => void;
  steps: WizardStepInfo[];
}

export type WizardHeaderProps = Omit<ProgressProps, 'steps'> & { property: SessionProperty };

const WizardHeader = ({ property, ...progressProps }: WizardHeaderProps) => {
  const steps = useWizardSteps({ property });
  const { activeStep } = progressProps;
  const { enTitle, title, description, stepNumber } =
    steps.find((s) => s.stepNumber === activeStep) || steps[0];

  useEffect(() => {
    if (window.heap) {
      window.heap.addEventProperties({ 'Step Number': stepNumber, 'Step Title': enTitle });
    }
  }, [stepNumber, enTitle]);

  return (
    <Container>
      <HeaderRow>
        <PropertyName>{property.name}</PropertyName>
        <LanguageSelector
          iconColor={IconColor.gray900}
          textColor={TextColor.secondary}
          size="small"
        />
      </HeaderRow>
      {activeStep <= steps.length && (
        <>
          <WizardProgress steps={steps} {...progressProps} />
          <H1>{title}</H1>
          <Subtitle>{description}</Subtitle>
        </>
      )}
    </Container>
  );
};

export const WizardProgress = ({ activeStep, steps, goToStep }: ProgressProps) => {
  return (
    <ProgressContainer>
      {steps.map(({ title, stepNumber }, i) => {
        const isActive = activeStep === stepNumber;
        return (
          <Step
            key={i}
            isActive={isActive}
            onClick={activeStep > 1 ? () => goToStep(stepNumber) : undefined}
          >
            <HideOnMobile>{title}</HideOnMobile>
          </Step>
        );
      })}
    </ProgressContainer>
  );
};

export default WizardHeader;
